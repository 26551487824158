import { render, staticRenderFns } from "./Proposta.vue?vue&type=template&id=1216eb07&"
import script from "./Proposta.vue?vue&type=script&lang=js&"
export * from "./Proposta.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QIcon,QSeparator,QList,QItem,QItemSection,QInput,QSelect,QCheckbox,QItemLabel,QCardActions,QSlider,QExpansionItem});qInstall(component, 'directives', {Ripple});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1216eb07')) {
      api.createRecord('1216eb07', component.options)
    } else {
      api.reload('1216eb07', component.options)
    }
    module.hot.accept("./Proposta.vue?vue&type=template&id=1216eb07&", function () {
      api.rerender('1216eb07', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PiattaformaRami/Pratiche/Rinnovi/Proposta.vue"
export default component.exports