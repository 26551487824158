var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ElencoProposte q-pa-md" }, [
    _vm._m(0),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-md-6 col-12 ", attrs: { align: "center" } },
        [
          _c(
            "q-card",
            {
              staticClass: "my-card shadow-12 shadow-transition",
              attrs: { bordered: "" },
            },
            [
              _c("q-card-section", { staticClass: "bg-primary text-white" }, [
                _c(
                  "div",
                  { staticClass: "text-h5 q-px-md", attrs: { align: "left" } },
                  [
                    _c("q-icon", { attrs: { name: "description" } }),
                    _vm._v(" " + _vm._s(_vm.proposta.compagnia) + " "),
                  ],
                  1
                ),
              ]),
              _c(
                "q-card-section",
                {
                  staticClass: "q-pt-none text-left",
                  staticStyle: {
                    "padding-left": "24px",
                    "padding-right": "24px",
                  },
                },
                [
                  _c(
                    "strong",
                    { staticStyle: { "font-size": "16px", color: "#A0A0A0" } },
                    [_vm._v(_vm._s(_vm.proposta.label))]
                  ),
                  _c("br"),
                  _c(
                    "div",
                    {
                      staticClass: "q-pb-md q-pt-md",
                      attrs: { align: "left" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "text-h3",
                          attrs: { name: "premio_annuale" },
                        },
                        [_vm._v(_vm._s(_vm.premio_da_mostrare))]
                      ),
                      _c("br"),
                    ]
                  ),
                ]
              ),
              _c("q-separator", { attrs: { inset: "" } }),
              _c(
                "q-card-section",
                [
                  _c(
                    "q-list",
                    [
                      _vm._l(
                        _vm.proposta.parametri,
                        function (parametro, index) {
                          return [
                            parametro.type == "text"
                              ? [
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      key: 40 + index,
                                      staticStyle: { padding: "0px" },
                                      attrs: { disable: !parametro.is_enabled },
                                    },
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c("q-input", {
                                            attrs: {
                                              disable: !parametro.is_enabled,
                                              filled: !parametro.is_enabled,
                                              outlined: "",
                                              label: parametro.label,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.onDomandaChange()
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "prepend",
                                                  fn: function () {
                                                    return [_vm._v(" € ")]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: parametro.value,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  parametro,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression: "parametro.value",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            parametro.type == "select"
                              ? [
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      key: 40 + index,
                                      staticStyle: { padding: "0px" },
                                      attrs: { disable: !parametro.is_enabled },
                                    },
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c("q-select", {
                                            attrs: {
                                              disable: !parametro.is_enabled,
                                              filled: !parametro.is_enabled,
                                              outlined: "",
                                              "emit-value": "",
                                              "map-options": "",
                                              options: parametro.options,
                                              label: parametro.label,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.onDomandaChange(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: parametro.value,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  parametro,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression: "parametro.value",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            parametro.type == "checkbox"
                              ? [
                                  _c(
                                    "q-item",
                                    {
                                      directives: [
                                        { name: "ripple", rawName: "v-ripple" },
                                      ],
                                      key: 40 + index,
                                      staticStyle: {
                                        padding: "0px",
                                        margin: "0px",
                                      },
                                      attrs: {
                                        disable: !parametro.is_enabled,
                                        tag: "label",
                                      },
                                    },
                                    [
                                      _c(
                                        "q-item-section",
                                        { attrs: { side: "", top: "" } },
                                        [
                                          _c("q-checkbox", {
                                            attrs: {
                                              disable: !parametro.is_enabled,
                                              filled: !parametro.is_enabled,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.onDomandaChange()
                                              },
                                            },
                                            model: {
                                              value: parametro.is_checked,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  parametro,
                                                  "is_checked",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "parametro.is_checked",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-section",
                                        { staticClass: "text-left" },
                                        [
                                          _c("q-item-label", {
                                            domProps: {
                                              textContent: _vm._s(
                                                parametro.label
                                              ),
                                            },
                                          }),
                                          _c("q-item-label", {
                                            attrs: { caption: "" },
                                            domProps: {
                                              textContent: _vm._s(
                                                parametro.description
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        }
                      ),
                      _c("br"),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "q-card-actions",
                { attrs: { align: "right" } },
                [
                  _c("QQCheckBox", {
                    ref: "MioCheckBox",
                    attrs: {
                      label: "Seleziona",
                      size: "sm",
                      name: "Seleziona",
                    },
                    on: { "btn-clicked": _vm.onBtnClicked },
                    model: {
                      value: _vm.proposta.is_checked,
                      callback: function ($$v) {
                        _vm.$set(_vm.proposta, "is_checked", $$v)
                      },
                      expression: "proposta.is_checked",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("fieldset", [
      _vm._m(1),
      _c("div", { staticClass: "justifi-center row" }, [
        _c("div", { staticClass: "col-12 col-md-12" }, [
          _c("table", { attrs: { border: "0", width: "100%" } }, [
            _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  { attrs: { width: "60%", align: "center" } },
                  [
                    _c("q-slider", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ha_consulenza,
                          expression: "ha_consulenza",
                        },
                      ],
                      attrs: {
                        color: "red",
                        "thumb-size": "50px",
                        "track-size": "15px",
                        "label-always": "",
                        "label-value": _vm.consulenza + "€",
                        min: _vm.importo_consulenza_minima,
                        max: _vm.importo_consulenza_massima,
                        "inner-min": _vm.importo_consulenza_minima,
                        "inner-max": _vm.importo_consulenza_massima,
                      },
                      on: { change: _vm.onCambiaValoreConsulenza },
                      model: {
                        value: _vm.consulenza,
                        callback: function ($$v) {
                          _vm.consulenza = $$v
                        },
                        expression: "consulenza",
                      },
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.ha_consulenza,
                            expression: "!ha_consulenza",
                          },
                        ],
                      },
                      [_c("h5", [_vm._v("Prodotto senza consulenza")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "td",
                  { attrs: { align: "right", NoWrap: "", colspan: "2" } },
                  [
                    _c("h5", [_vm._v("Costo di acquisto garantito")]),
                    _c("h2", { staticClass: "q-mb-sm" }, [
                      _c(
                        "strong",
                        { attrs: { id: "premio_totale_consulenza" } },
                        [_vm._v(_vm._s(_vm.premio_totale))]
                      ),
                      _vm._v(" Euro"),
                    ]),
                  ]
                ),
              ]),
              _c("tr", [
                _c(
                  "td",
                  [
                    _c(
                      "q-expansion-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isSede,
                            expression: "isSede",
                          },
                        ],
                        staticStyle: {
                          "font-size": "18px",
                          border: "1px SOLID #000",
                        },
                        attrs: {
                          "expand-separator": "",
                          icon: "mdi-eye-plus",
                          name: "visualizza_altre_proposte",
                          label: "Ulteriori informazioni",
                          caption: "Clicca QUI per visualizzarle",
                          color: "primary",
                          "track-size": "40px",
                          "label-lines": "",
                        },
                        model: {
                          value: _vm.altri_dati_visibili,
                          callback: function ($$v) {
                            _vm.altri_dati_visibili = $$v
                          },
                          expression: "altri_dati_visibili",
                        },
                      },
                      [
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { bordered: "", separator: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { align: "left" } },
                                      [_vm._v("Premio proposta selezionata")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  { attrs: { align: "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.premio_contratto) + " Euro"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { align: "left" } },
                                      [_vm._v("Importo Diritti")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  { attrs: { align: "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.importo_diritti_aby.toFixed(2)
                                      ) + " Euro"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { align: "left" } },
                                      [
                                        _vm._v(
                                          "Consulenza minima selezionabile"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  { attrs: { align: "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.importo_consulenza_minima.toFixed(2)
                                      ) + " Euro"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { align: "left" } },
                                      [
                                        _vm._v(
                                          "Consulenza massima selezionabile"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  { attrs: { align: "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.importo_consulenza_massima.toFixed(
                                          2
                                        )
                                      ) + " Euro"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { align: "left" } },
                                      [_vm._v("Consulenza impostata")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  { attrs: { align: "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.consulenza.toFixed(2)) +
                                        " Euro"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { align: "left" } },
                                      [_vm._v("Provvigioni di Compagnia")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  { attrs: { align: "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.proposta.provvigioni.toFixed(2)
                                      ) + " Euro"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { align: "left" } },
                                      [
                                        _vm._v(
                                          "Quota consulenza Unità Operativa"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  { attrs: { align: "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.importo_provvigioni_consulenza_collaboratore.toFixed(
                                          2
                                        )
                                      ) + " Euro"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { align: "left" } },
                                      [_vm._v("Quota consulenza Aby")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-section",
                                  { attrs: { align: "right" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.importo_provvigioni_consulenza_aby
                                      ) + " Euro"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._m(2),
                _c("td", { attrs: { align: "right" } }, [
                  _c("h5", [
                    _c("strong", [_vm._v(_vm._s(_vm.provvigioni_totali))]),
                    _vm._v(" Euro"),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("hr"),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-md-4", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "INDIETRO",
              color: "blue-grey",
              icon: "undo",
              size: "md",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onIndietroClicked.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "ANNULLA RINNOVO",
              color: "blue-grey",
              icon: "undo",
              size: "md",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onAnnullaRinnovo.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "AVANTI",
              color: "blue-grey",
              icon: "mdi-arrow-right-bold",
              size: "md",
              icon_align: "right",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onAvantiClicked.apply(null, arguments)
              },
            },
          }),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-md-7 col-12", attrs: { align: "center" } },
        [
          _c("br"),
          _c("br"),
          _c("span", { staticClass: "text-h4" }, [
            _vm._v("Proposta selezionata"),
          ]),
          _c("hr"),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("h5", [_vm._v("Consulenza")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { attrs: { align: "right" } }, [
      _c("h6", [_vm._v("Provvigioni:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }